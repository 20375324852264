<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Новости</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск новостей" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <v-btn depressed class="button-blue" @click="dialogAddGroup = true">
        Добавить новость
      </v-btn>
      <div class="subtitle-block-count">
        Всего новостей: {{ paginationSupply.itemCount }}
      </div>
    </div>
    <div class="container-table">
      <Table
        @scrollMethod="checkForScrolling"
        :items="searchingItems"
        :headers="headers"
        headerFixed
        @click:row="goToDetail"
      >
      </Table>
    </div>
    <Dialog
      v-model="dialogAddGroup"
      refForm="testForm"
      titleText="Добавление новости"
      :mainItems="[
        {
          type: 'text',
          maxLength: 250,
          label: 'Название',
          value: this.newItem.title,
          vmodel: (e) => (this.newItem.title = e),
          valid: true,
        },
        {
          type: 'textarea',
          maxLength: 4000,
          label: 'Описание',
          value: this.newItem.description,
          vmodel: (e) => (this.newItem.description = e),
        },
        {
          type: 'image',
          label: 'Обложка',
          valid: true,
          value: this.newItem.photo,
          vmodel: (e) => (this.newItem.photo = e),
        },
      ]"
      @click:success="addItem"
      :buttonsFooter="{
        success: {
          title: 'Добавить новость',
          loading: true,
        },
      }"
    >
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
export default {
  components: {
    SearchInput,
  },
  data: () => ({
    debounceFunc: null,
    loading: true,
    search: "",
    items: [],
    itemsAll: [],
    dialogAddGroup: false,
    newItem: {
      photo: "",
    },
    paginationSupply: {
      isScroll: false,
      itemCount: null,
      paginationParam: {
        page: 1,
        take: 50,
        pageCount: null,
        hasNextPage: null,
        hasPreviousPage: null,
      },
      itemsParams: [],
      api: "news",
    },
    headers: [
      {
        text: "Дата",
        align: "flex-start",
        sortable: false,
        value: "datePublished",
        width: "10%",
      },
      {
        text: "Заголовок",
        align: "flex-start",
        sortable: false,
        value: "title",
        width: "35%",
      },
      {
        text: "Описание",
        sortable: false,
        value: "description",
        width: "35%",
        align: "flex-center",
      },
      {
        text: "Статус",
        align: "flex-end",
        sortable: false,
        value: "isPublished",
        width: "20%",
      },
    ],
  }),
  computed: {
    searchingItems() {
      const filteredItems = this.itemsAll.filter((i) =>
        i.title?.toLowerCase().includes(this.search.toLowerCase())
      );
      return filteredItems;
    },
  },
  methods: {
    getItems() {
      this.$axios
        .get(
          `${this.$store.getters.apiV7}/news?${new URLSearchParams({
            page: this.paginationSupply.paginationParam.page,
            take: this.paginationSupply.paginationParam.take,
          })}`,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.newsPrettier(res.data.data);
          this.itemsAll = res.data.data;
          this.paginationSupply.itemCount = res.data.pagination.itemCount;
          this.paginationSupply.paginationParam.pageCount = res.data.pagination.pageCount;
          this.paginationSupply.paginationParam.hasNextPage = res.data.pagination.hasNextPage;
          this.paginationSupply.paginationParam.hasPreviousPage = res.data.pagination.hasPreviousPage;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    newsPrettier(array) {
      for (const el of array) {
        el.description = this.wordTruncation(el.description, 0, 100);
        el.title = this.wordTruncation(el.title, 0, 100);

        el.isPublished
          ? (el.isPublished = "Опубликовано")
          : (el.isPublished = "Не опубликовано");
        if (el.datePublished > 0) {
          el.datePublished = this.$moment(
            Number.parseInt(el.datePublished)
          ).format("DD.MM.YYYY");
        } else {
          el.datePublished = "Нет даты";
        }
      }
    },

    async saveImage(el) {
      const formData = new FormData();
      formData.append("file", el.photo);

      await this.$axios
        .post(`${this.$store.getters.apiV7}/upload`, formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          el.photo = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    async addItem() {
      await this.saveImage(this.newItem);

      this.$axios
        .post(
          `${this.$store.getters.apiV7}/news`,
          {
            datePublished: Date.now(),
            title: this.newItem.title,
            photo: this.newItem.photo ?? "",
            description: this.newItem.description ?? "",
            isPublished: this.newItem.isPublished ?? false,
            isSendPush: this.newItem.isSendPush ?? false,
            topics: this.newItem.topics ?? [],
            groups: this.newItem.groups ?? [],
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.$router.push({ name: "News", params: { id: res.data.id } });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          if (err.response.data.errors[0].code === "too_big") {
            switch (err.response.data.errors[0].type) {
              case "string":
                this.$store.commit(
                  "errorTextSet",
                  "Размер заголовка должен быть не более 250 символов, текста не более 4000 символов"
                );
                break;
              case "object":
                this.$store.commit(
                  "errorTextSet",
                  "Размер фото должен быть не более 50 Мб"
                );
                break;
              default:
                this.$store.commit(
                  "errorTextSet",
                  "Ошибка при создании новости"
                );
                break;
            }
          }
          this.useFallback();
        });
    },

    async getInvolvedPagination(isScroll) {
      if (isScroll) {
        const { itemsNew, paginationNew } = await this.involvePagination(
          this.paginationSupply,
          this.$store.getters
        );
        if (itemsNew) {
          this.newsPrettier(itemsNew);
          this.itemsAll.push(...itemsNew);
        }
        this.paginationParam = paginationNew;
      }
    },

    async checkForScrolling(pixels) {
      if (pixels.target.scrollTop >= 10) {
        this.debounceFunc(() => {
          if (
            this.paginationSupply.paginationParam.page <=
            this.paginationSupply.paginationParam.pageCount
          ) {
            this.paginationSupply.paginationParam.page += 1;
            this.getInvolvedPagination(true);
          }
        }, 300);
      }
    },

    goToDetail(item) {
      this.$router.push({ name: "News", params: { id: item.id } });
    },
  },
  created() {
    this.debounceFunc = this.createDebounce();
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
    .table-icon {
      filter: invert(44%) sepia(35%) saturate(0%) hue-rotate(179deg)
        brightness(91%) contrast(80%);
      margin: 0px 4px;
    }
  }
}
</style>
